import React from "react"
import Dropzone from "../components/dropzone";
import SEO from "../components/seo"
import LayoutInfo from "../components/layout-info"
import Progress from "../components/progress"
import styles from './namelist.module.scss'
import checkImage from "./../images/baseline-check_circle_outline-24px.svg"

import API, { graphqlOperation } from '@aws-amplify/api';
import config from '../aws-exports';

class NamelistPage extends React.Component {

  constructor(props) {
    super(props);

    API.configure(config);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false
    };
    this.onFileRemoved = this.onFileRemoved.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  onFileRemoved(file) {

    this.setState((prevState) => {
      const files = prevState.files;
      const newFiles = [];

      for (var i = 0; i < files.length; i++) {
        if (files[i].name !== file.name) {
          newFiles.push(files[i]);
        }
      }

      return {
        files: newFiles
      };
    });

  }

  async uploadFiles(signedUrls) {
    
    const promises = [];
    const files = this.state.files;
    for (var i = 0; i < files.length; i++) {
      promises.push(this.sendRequest(files[i], signedUrls[i]));
    }

    try {
      await Promise.all(promises);
      //console.log('All promises finished');
      this.setState({ failedToUpload: false, successfullUploaded: true, uploading: false });
    } catch (e) {
      
      console.error(e);
      var logger = _LTracker || [];
      logger.push("Failed to upload all namelist files");
      logger.push(e);
      this.setState({ failedToUpload: true, successfullUploaded: false, uploading: false });
    }
  }

  startUploadProcess = (input) => {
    const query = `query UploadFiles($input: UploadFilesInput) {
          uploadFiles(input: $input) {
            success  
            uploadUrls
          }
        }`;

    API.graphql(graphqlOperation(query, { input: input }))
      .then(result => {
        if (result.data.uploadFiles.success) {
          this.uploadFiles(result.data.uploadFiles.uploadUrls);
        }

      })
      .catch(e => {
        console.log('ERROR UPLOADING FILES');
        var logger = _LTracker || [];
        logger.push("Failed to register namelist file record");
                
        if (e) {
          console.error(e);
          logger.push(e);
        }
        this.setState({ failedToUpload: true, successfullUploaded: false, uploading: false });
      });
  }


  getUploadForm(form) {
    const formData = new FormData(form);
    const uploadData = {};

    if (`entries` in formData) {
      //console.log('Using primary way to get name list info');
      for (let entry of formData.entries()) {
        uploadData[entry[0]] = entry[1];
      }
    } else {
      // not all browsers supports formData.entries()
      uploadData.name = form.name.value;
      uploadData.partner = form.partner.value;
      uploadData.relatedTo = form.relatedTo.value;
      uploadData.email = form.email.value;
      uploadData.info = form.info.value;
    }

    const fileNames = [];
    const filesContentType = [];
    this.state.files.forEach(file => {
      fileNames.push(file.name);
      filesContentType.push(file.type);

    });
    uploadData.filesNames = fileNames;
    uploadData.filesContentType = filesContentType;

    return uploadData;
  }


  sendRequest(file, signedUrl) {

    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          console.log('progress');
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      req.open("PUT", signedUrl);
      req.setRequestHeader("Content-Type", file.type);
      req.send(file);
    });
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  renderFailure() {
    if (this.state.failedToUpload) {
      return (<div className="alert alert-danger" role="alert">Noe gikk galt. Vennligst prøv igjen litt senere. </div>);
    }
    return null;
  }

  renderSuccess() {
    
    if (this.state.successfullUploaded) {
      return (
        <div>
          <div className="alert alert-success" role="alert">Navnelistene ble lastet opp vellykket. </div>
          Filene som ble lastet opp:
          <ul>
            {this.state.files.map(file => {
              return (<li>
                <span className={styles.Filename}>{file.name}</span>
              </li>);
            })}
          </ul>
        </div>
      );
    }
    return null;
  }

  renderForm() {
    if (this.state.successfullUploaded){
      return null;
    }

    return (<form onSubmit={this.onUpload} noValidate>
      <div className="form-group">
        <label htmlFor="fullName" className="w-100">Fullt navn på innsender
          <input type="text" className="form-control" id="fullName" name="name" placeholder="Skriv inn ditt fullt navn" required />
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="email" className="w-100">E-post
          <input type="email" className="form-control" id="email" name="email" aria-describedby="emailHelp" placeholder="Skriv inn e-post addresse" required />
        </label>
        <small id="emailHelp" className="form-text text-muted">Brukes kun til evt. spørsmål rundt navnelisten.</small>
      </div>
      <div className="form-group">
        <label htmlFor="school" className="w-100">Skolenavn
          <input type="text" className="form-control" id="school" name="relatedTo" placeholder="Skriv inn navnet til skolen navnelisten er for" required />
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="partner" className="w-100">Leirskole
          <input type="text" className="form-control" id="partner" name="partner" placeholder="Skriv inn navnet til leirskolen oppholder er/var på." required />
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="info" className="w-100">Ekstra info
          <input type="text" className="form-control" id="info" name="info" placeholder="Hvilke ukenr oppholdet er/var på. Hvis flere klasser spesifiser hvilke uke de enkelte klassene er/var på." />
        </label>
      </div>
      <div className="form-group">
        Vi fortrekker Excel lister, ikke PDF, bilder av lister, etc,  hvis mulig for å unngå feil ved konvertering av listene.
        <div className={`${styles.FilesArea} ${this.state.files.length === 0 ? 'invalid' : ''} `}>
          <div className="row">
            <div className="col-6">
              <Dropzone onFilesAdded={this.onFilesAdded} disabled={this.state.uploading || this.state.successfullUploaded} />
            </div>
            <div className={`col-6 ${styles.Files} text-left`}>
              <div className="row">
                <span className={styles.FilesHeader}>Navnelistefiler:</span>
                <hr className={styles.FilesDivider} />
              </div>

              {this.state.files.map(file => {
                return (<div key={file.name} className="row">
                  <span className={styles.Filename}>{file.name}</span>

                  <button type="button" className={`btn btn-link ${styles.FileDelete} ml-auto`} aria-label="Delete" title="Fjern filen" onClick={() => this.onFileRemoved(file)}>
                    <span className="material-icons">
                      delete
                    </span>
                  </button>

                  {this.renderProgress(file)}
                </div>);
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="form-check">

          <input id="approve" type="checkbox" className="form-check-input" required />
          <label className="form-check-label" htmlFor="approve">Leirskoledagboken AS kan bruke informasjon nevnt ovenfor i henhold til gjeldende lovverk for personvern.</label>

        </div>
      </div>

      <button type="submit" className="btn btn-primary w-100" disabled={this.state.uploading || this.state.successfullUploaded}>
        {!this.state.uploading ?
          <span>Last opp navnelistene sikkert&nbsp;</span>
          : this.state.successfullUploaded ?
            <span className="">Navnelistene lastet opp vellykket</span>
            : <span className="">Laster opp navnelistene ...</span>
        }

        {this.state.uploading ?
          <div className="spinner-border spinner-border-sm" role="status">
          </div>
          : null}
      </button>

    </form>);
  }


  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];

    if (this.state.uploading || this.state.successfullUploaded) {
      //console.log(uploadProgress ? uploadProgress.percentage : 'percentage not set');
      return (
        <div className={styles.ProgressWrapper}>

          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className={styles.CheckIcon}
            alt="Ferdig"
            src={checkImage}
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  onUpload = (e => {
    var form = e.target;
    e.preventDefault();

    if (form.checkValidity() === false || this.state.files.length === 0) {
      e.stopPropagation();
      form.classList.add('was-validated');
      return;
    }
    this.setState({ uploadProgress: {}, uploading: true });

    this.startUploadProcess(this.getUploadForm(e.target))

  });

  render() {

    return (
      <LayoutInfo>
        <SEO title="Navnelister" />

        <div className="row">
          <div className="col">
            <h1 className="card-title">Last opp navneliste</h1>
            <div>
              <dl>
                <dt>Navneliste</dt>
                <dd>
                  <p>
                    Med mindre annet er avtalt, trenger vi navn og kontaktinformasjonen til de som skal få tilgang til å kjøpe leirskoledagboken.
                    En fil som inneholder denne informasjonen, blir vanligvis eksportert fra skolesystemet.
                  </p>

                  <p>Hvis du trenger hjelp med eksport fra systemet du bruker, kan du kontakte systemleverandøren din. For eventuelle andre spørsmål, er du velkommen til å kontakte oss.</p>
                </dd>
                <dt>Full konfidensialitet og personvern</dt>
                <dd>
                  Systemet bruker kryptert ende-til-ende filoverføring, og holder filene dine trygge fra
                  uautorisert tilgang til enhver tid. Dette er en sikker måte å sende konfidensiell informasjon på,
                  og er i samsvar med gjeldende personopplysnings- og personvernregler.

                </dd>
              </dl>

              {this.renderFailure()}
              {this.renderSuccess()}
              {this.renderForm()}

            </div>
          </div>
        </div>
      </LayoutInfo>
    )
  }


}
export default NamelistPage
